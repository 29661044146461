<template>
  <div style="position: relative;padding-top:15px;">
    <div style="position: absolute;left: 0;top:-35px;">
      <v-btn color="primary" outlined small @click="$emit('openStyles')">
        <v-icon>
          mdi-chevron-left
        </v-icon>
        Back to content
      </v-btn>
    </div>
    <div>
      <label style="display:block;margin-bottom: 15px;">Change form image for cart</label>
      <vue-dropzone
        id="upload"
        ref="upload"
        :options="dropzoneOptions"
        :destroyDropzone="false"
        style="margin-bottom: 15px"
        @vdropzone-sending="customEvent"
        @vdropzone-removed-file="handleRemove"
        @vdropzone-success="handleSuccess"
        @vdropzone-max-files-exceeded="logoMaxFileSexceeded"
        @vdropzone-mounted="mountDropzone"
      />
    </div>
    <div style="display: flex; align-items: center; margin: 10px 0">
      <label
        class="label"
        style="width: 250px; margin-bottom: 0; margin-right: 15px"
        >Pro group title color</label
      >
      <v-menu
        top
        nudge-left="16"
        nudge-bottom="98"
        :close-on-content-click="false"
        v-model="show_btn_title_color"
      >
        <template v-slot:activator="{ on }">
          <div
            class="colorpicker"
            :style="`background-color:${landing.order_form.styles.title_color}`"
            v-on="on"
          />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker
              v-model="landing.order_form.styles.title_color"
              @input="show_btn_title_color = false"
              flat
            />
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
    <div style="display: flex; align-items: center; margin: 10px 0">
      <label
        class="label"
        style="width: 250px; margin-bottom: 0; margin-right: 15px"
        >Pro group label color</label
      >
      <v-menu
        top
        nudge-left="16"
        nudge-bottom="98"
        :close-on-content-click="false"
        v-model="show_btn_label_color"
      >
        <template v-slot:activator="{ on }">
          <div
            class="colorpicker"
            :style="`background-color:${landing.order_form.styles.label_color}`"
            v-on="on"
          />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker
              v-model="landing.order_form.styles.label_color"
              @input="show_btn_label_color = false"
              flat
            />
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
    <div style="display: flex; align-items: center; margin: 10px 0">
      <label
        class="label"
        style="width: 250px; margin-bottom: 0; margin-right: 15px"
        >Pro group price color</label
      >
      <v-menu
        top
        nudge-left="16"
        nudge-bottom="98"
        :close-on-content-click="false"
        v-model="show_btn_price_color"
      >
        <template v-slot:activator="{ on }">
          <div
            class="colorpicker"
            :style="`background-color:${landing.order_form.styles.price_color}`"
            v-on="on"
          />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker
              v-model="landing.order_form.styles.price_color"
              @input="show_btn_price_color = false"
              flat
            />
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
    <div style="display: flex; align-items: center; margin: 10px 0">
      <label
        class="label"
        style="width: 250px; margin-bottom: 0; margin-right: 15px"
        >Order Button Name Color</label
      >
      <v-menu
        top
        nudge-left="16"
        nudge-bottom="98"
        :close-on-content-click="false"
        v-model="show_btn_name_color"
      >
        <template v-slot:activator="{ on }">
          <div
            class="colorpicker"
            :style="`background-color:${item.form.btn_color}`"
            v-on="on"
          />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker
              v-model="item.form.btn_color"
              @input="show_btn_name_color = false"
              flat
            />
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
    <div style="display: flex; align-items: center; margin: 10px 0">
      <label
        class="label"
        style="width: 250px; margin-bottom: 0; margin-right: 15px"
        >Order Button Background Color</label
      >
      <v-menu
        top
        nudge-left="16"
        nudge-bottom="98"
        :close-on-content-click="false"
        v-model="show_btn_bg_color"
      >
        <template v-slot:activator="{ on }">
          <div
            class="colorpicker"
            :style="`background-color:${item.form.btn_bg}`"
            v-on="on"
          />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker
              v-model="item.form.btn_bg"
              @input="show_btn_bg_color = false"
              flat
            />
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
    <div style="display: flex; align-items: center; margin: 10px 0">
      <label
        class="label"
        style="width: 250px; margin-bottom: 0; margin-right: 15px"
        >Order Button Border Color</label
      >
      <v-menu
        top
        nudge-left="16"
        nudge-bottom="98"
        :close-on-content-click="false"
        v-model="show_btn_border_color"
      >
        <template v-slot:activator="{ on }">
          <div
            class="colorpicker"
            :style="`background-color:${item.form.btn_border}`"
            v-on="on"
          />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker
              v-model="item.form.btn_border"
              @input="show_btn_border_color = false"
              flat
            />
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
    <div style="margin: 10px 0; display: flex; align-items: center">
      <label
        class="label"
        style="width: 250px; margin-bottom: 0; margin-right: 15px"
        >Order Button Name</label
      >
      <v-text-field
        style="max-width: 250px"
        outlined
        solo
        flat
        hide-details
        dense
        v-model="item.form.btn_name"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import vueDropzone from "vue2-dropzone";
export default {
  name: "FormOrderStyles",
  props: ["item"],
  data() {
    return {
      show_btn_name_color: false,
      show_btn_bg_color: false,
      show_btn_border_color: false,
      show_btn_label_color: false,
      show_btn_title_color: false,
      show_btn_price_color: false,
      dropzoneOptions: {
        addRemoveLinks: true,
        maxFile: 1,
        maxFileSize: 3,
        destroyDropzone: false,
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        dictFileTooBig: "File is too big ({{filesize}}mb). Max file size: 3mb",
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
      },
    };
  },
  methods: {
    mountDropzone() {
      if (this.item.form.title_img) {
        let mockFile = {
          accepted: true,
          kind: "image",
          ...this.item.form.title_img,
        };
        this.$refs.upload.manuallyAddFile(mockFile, this.item.form.title_img);
        this.$refs.upload.dropzone.emit(
          "thumbnail",
          mockFile,
          this.item.form.title_img
        );
        this.$refs.upload.dropzone.emit("complete", mockFile);
      }
    },
    logoMaxFileSexceeded(file) {
      this.$refs.upload.removeAllFiles();
      this.$refs.upload.addFile(file);
    },
    async handleRemove() {
      this.item.form.title_img = null;
    },
    handleSuccess(file) {
      this.item.form.title_img = file.xhr.response;
    },
    async customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append("lending_settings_id", this.landing.settings_id);
      formData.append("image", _file);
    },
  },
  components: {
    vueDropzone,
  },
  computed: {
    ...mapState(["landing"]),
  },
};
</script>

<style scoped></style>
