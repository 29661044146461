import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative","padding-top":"15px"}},[_c('div',{staticStyle:{"position":"absolute","left":"0","top":"-35px"}},[_c(VBtn,{attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.$emit('openStyles')}}},[_c(VIcon,[_vm._v(" mdi-chevron-left ")]),_vm._v(" Back to content ")],1)],1),_c('div',[_c('label',{staticStyle:{"display":"block","margin-bottom":"15px"}},[_vm._v("Change form image for cart")]),_c('vue-dropzone',{ref:"upload",staticStyle:{"margin-bottom":"15px"},attrs:{"id":"upload","options":_vm.dropzoneOptions,"destroyDropzone":false},on:{"vdropzone-sending":_vm.customEvent,"vdropzone-removed-file":_vm.handleRemove,"vdropzone-success":_vm.handleSuccess,"vdropzone-max-files-exceeded":_vm.logoMaxFileSexceeded,"vdropzone-mounted":_vm.mountDropzone}})],1),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin":"10px 0"}},[_c('label',{staticClass:"label",staticStyle:{"width":"250px","margin-bottom":"0","margin-right":"15px"}},[_vm._v("Pro group title color")]),_c(VMenu,{attrs:{"top":"","nudge-left":"16","nudge-bottom":"98","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"colorpicker",style:(("background-color:" + (_vm.landing.order_form.styles.title_color)))},on))]}}]),model:{value:(_vm.show_btn_title_color),callback:function ($$v) {_vm.show_btn_title_color=$$v},expression:"show_btn_title_color"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},on:{"input":function($event){_vm.show_btn_title_color = false}},model:{value:(_vm.landing.order_form.styles.title_color),callback:function ($$v) {_vm.$set(_vm.landing.order_form.styles, "title_color", $$v)},expression:"landing.order_form.styles.title_color"}})],1)],1)],1)],1),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin":"10px 0"}},[_c('label',{staticClass:"label",staticStyle:{"width":"250px","margin-bottom":"0","margin-right":"15px"}},[_vm._v("Pro group label color")]),_c(VMenu,{attrs:{"top":"","nudge-left":"16","nudge-bottom":"98","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"colorpicker",style:(("background-color:" + (_vm.landing.order_form.styles.label_color)))},on))]}}]),model:{value:(_vm.show_btn_label_color),callback:function ($$v) {_vm.show_btn_label_color=$$v},expression:"show_btn_label_color"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},on:{"input":function($event){_vm.show_btn_label_color = false}},model:{value:(_vm.landing.order_form.styles.label_color),callback:function ($$v) {_vm.$set(_vm.landing.order_form.styles, "label_color", $$v)},expression:"landing.order_form.styles.label_color"}})],1)],1)],1)],1),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin":"10px 0"}},[_c('label',{staticClass:"label",staticStyle:{"width":"250px","margin-bottom":"0","margin-right":"15px"}},[_vm._v("Pro group price color")]),_c(VMenu,{attrs:{"top":"","nudge-left":"16","nudge-bottom":"98","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"colorpicker",style:(("background-color:" + (_vm.landing.order_form.styles.price_color)))},on))]}}]),model:{value:(_vm.show_btn_price_color),callback:function ($$v) {_vm.show_btn_price_color=$$v},expression:"show_btn_price_color"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},on:{"input":function($event){_vm.show_btn_price_color = false}},model:{value:(_vm.landing.order_form.styles.price_color),callback:function ($$v) {_vm.$set(_vm.landing.order_form.styles, "price_color", $$v)},expression:"landing.order_form.styles.price_color"}})],1)],1)],1)],1),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin":"10px 0"}},[_c('label',{staticClass:"label",staticStyle:{"width":"250px","margin-bottom":"0","margin-right":"15px"}},[_vm._v("Order Button Name Color")]),_c(VMenu,{attrs:{"top":"","nudge-left":"16","nudge-bottom":"98","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"colorpicker",style:(("background-color:" + (_vm.item.form.btn_color)))},on))]}}]),model:{value:(_vm.show_btn_name_color),callback:function ($$v) {_vm.show_btn_name_color=$$v},expression:"show_btn_name_color"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},on:{"input":function($event){_vm.show_btn_name_color = false}},model:{value:(_vm.item.form.btn_color),callback:function ($$v) {_vm.$set(_vm.item.form, "btn_color", $$v)},expression:"item.form.btn_color"}})],1)],1)],1)],1),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin":"10px 0"}},[_c('label',{staticClass:"label",staticStyle:{"width":"250px","margin-bottom":"0","margin-right":"15px"}},[_vm._v("Order Button Background Color")]),_c(VMenu,{attrs:{"top":"","nudge-left":"16","nudge-bottom":"98","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"colorpicker",style:(("background-color:" + (_vm.item.form.btn_bg)))},on))]}}]),model:{value:(_vm.show_btn_bg_color),callback:function ($$v) {_vm.show_btn_bg_color=$$v},expression:"show_btn_bg_color"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},on:{"input":function($event){_vm.show_btn_bg_color = false}},model:{value:(_vm.item.form.btn_bg),callback:function ($$v) {_vm.$set(_vm.item.form, "btn_bg", $$v)},expression:"item.form.btn_bg"}})],1)],1)],1)],1),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin":"10px 0"}},[_c('label',{staticClass:"label",staticStyle:{"width":"250px","margin-bottom":"0","margin-right":"15px"}},[_vm._v("Order Button Border Color")]),_c(VMenu,{attrs:{"top":"","nudge-left":"16","nudge-bottom":"98","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"colorpicker",style:(("background-color:" + (_vm.item.form.btn_border)))},on))]}}]),model:{value:(_vm.show_btn_border_color),callback:function ($$v) {_vm.show_btn_border_color=$$v},expression:"show_btn_border_color"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},on:{"input":function($event){_vm.show_btn_border_color = false}},model:{value:(_vm.item.form.btn_border),callback:function ($$v) {_vm.$set(_vm.item.form, "btn_border", $$v)},expression:"item.form.btn_border"}})],1)],1)],1)],1),_c('div',{staticStyle:{"margin":"10px 0","display":"flex","align-items":"center"}},[_c('label',{staticClass:"label",staticStyle:{"width":"250px","margin-bottom":"0","margin-right":"15px"}},[_vm._v("Order Button Name")]),_c(VTextField,{staticStyle:{"max-width":"250px"},attrs:{"outlined":"","solo":"","flat":"","hide-details":"","dense":""},model:{value:(_vm.item.form.btn_name),callback:function ($$v) {_vm.$set(_vm.item.form, "btn_name", $$v)},expression:"item.form.btn_name"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }