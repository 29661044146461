<template>
  <div style="position: relative;padding-top:15px;">
    <div style="position: absolute;left: 0;top:-35px;">
      <v-btn color="primary" outlined small @click="$emit('openSettings')">
        <v-icon>
          mdi-chevron-left
        </v-icon>
        Back to content
      </v-btn>
    </div>
    <div style="margin: 10px 0">

      <div style="display: flex; align-items: center">
        <label class="label" style="margin-right: 15px">Type of form?</label>
        <v-radio-group row v-model="item.form.type_of_order">
          <v-radio
                  style="margin-right: 15px"
                  v-for="type in orderTypes"
                  :label="type.label"
                  :value="type.value"
                  :key="type.id"
          >
          </v-radio>
        </v-radio-group>
      </div>
      <div  >

        <div style="display: flex;flex-direction: column;margin-bottom: 15px;">
          <label class="label" style="margin-bottom: 15px;display: block;">Emails for send order</label>
          <div v-for="(email,emailIndex) in emailLength" :key="emailIndex">
            <v-text-field :rules="emailRules" v-model="item.form.emails[email]" dense outlined style="max-width: 400px;" append-outer-icon="mdi-close-circle-outline" @click:append-outer="deleteEmail(email)"></v-text-field>
          </div>
          <v-btn color="primary" small outlined @click="addEmail" style="max-width: 150px;" >+Add email</v-btn>
        </div>
        <div style="display: flex;flex-direction: column;margin-bottom: 15px;">
          <label class="label" style="margin-bottom: 15px;display: block;">Telegram users for send order</label>
          <div v-for="(user,index) in arrTelegrams" :key="user">
            <v-select :value="item.form.telegrams[user]" @change="(val) => changeUser(val,index)" item-text="first_name" item-disabled="disabled" item-value="id" return-object :items="telegramUsersAvaliable(item.form.telegrams[user])"  dense outlined append-outer-icon="mdi-close-circle-outline" @click:append-outer="deleteUser(user)">
              <template v-slot:item="{ item }">
                {{item.first_name}} {{item.last_name}}
              </template>
              <template v-slot:item="{ item }">
                {{item.first_name}} {{item.last_name}}
              </template>
            </v-select>
          </div>
          <v-btn color="primary" small outlined @click="addUser" style="max-width: 150px;" :disabled="enabledTelegramUsers.length === 0">+Add user</v-btn>
          <div v-if="enabledTelegramUsers.length === 0">
            You need create more users <span @click="goToTelegramUsers" style="color:#007aff;cursor: pointer;">here</span>
          </div>
        </div>
      </div>
      <div style="display: flex; align-items: center">
        <label class="label" style="margin-right: 15px"
          >Send order request?</label
        >
        <v-checkbox v-model="item.form.send_request"> </v-checkbox>
      </div>
      <label class="label">Hidden title</label>
      <v-text-field
        outlined
        dense
        v-model="item.form.hidden_title"
        style="max-width: 50%"
      ></v-text-field>
      <label class="label">Hidden id</label>
      <v-text-field
        outlined
        dense
        v-model="item.form.hidden_id"
        style="max-width: 50%"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex"
  import TelegramUserService from "../../../../services/telegramUser.service";
export default {
  name: "FormOrderSettings",
  props: ["item"],
  data() {
    return {
      emailRules: [
        v => !!v || this.$t('landing.valid["E-mail обязателен"]'),
        v => /.+@.+\..+/.test(v) || this.$t('landing.valid["E-mail должен быть валидным"]'),
      ],
      orderTypes: [
        { id: 0, value: "request", label: "Request" },
        { id: 1, value: "items", label: "Items" },
      ],
    };
  },
  methods:{
    changeUser(user,index){
      this.item.form.telegrams.splice(index,1,user);
    },
    telegramUsersAvaliable(user){
      return this.$store.state.telegramUsers.reduce((acc,item) => {
        if(user){
          if(item.id === user.id){
            acc.push(item);
          }
        }
        if (!this.item.form.telegrams.some(e => e.telegram_key === item.telegram_key)) {
          acc.push(item);
        }
        return acc
      },[])
    },
    goToTelegramUsers(){
      this.landing.order.tab = 6;
      this.$store.commit('setPage','order');
      this.$store.state.tab = 4;
    },
    deleteUser(index){
      this.item.form.telegrams.splice(index,1);
    },
    addUser(){
      let item = JSON.parse(JSON.stringify(this.enabledTelegramUsers[0]));
      item.disabled = true;
      this.item.form.telegrams.push(item);
    },
    deleteEmail(index){
      this.item.form.emails.splice(index,1);
    },
    addEmail(){
      this.item.form.emails.push("");
    },
  },
  async created() {
    if(!this.item.form.emails){
      this.item.form.emails = [];
    }
    if(!this.item.form.telegrams){
      this.item.form.telegrams = [];
    }
    this.$store.state.telegramUsers = await TelegramUserService.getUserList(this.$route.params.id);
  },
  computed:{
    ...mapState(['landing']),
    emailLength(){
      let arr=  [];
      for(let i = 0;i<this.item.form.emails.length;i++){
        arr.push(i)
      }
      return arr;
    },
    enabledTelegramUsers(){
      return this.$store.state.telegramUsers .reduce((acc,item) => {
        const candidateIndex = this.item.form.telegrams.find(x => x.id === item.id);
        if(!candidateIndex){
          acc.push(item);
        }
        return acc
      },[])
    },
    arrTelegrams(){
      let arr = []
      for(let i = 0;i<this.item.form.telegrams.length;i++){
        arr.push(i);
      }
      return arr
    }
  },
};
</script>

<style scoped></style>
