<template>
  <div>
    <div v-if="landing.free">
      For activate this feature change tariff plan
      <a @click="billing">here</a>
    </div>
    <div style="display:flex;align-items:center;margin-bottom:15px;">
      <label
        class="label"
        :style="`color:${landing.free ? 'rgba(0,0,0,0.38)' : '#000'}`"
        style="margin:0;display:block;width:200px;"
        >Show Order Form</label
      >
      <v-switch
        hide-details
        v-model="item.form.show"
        :disabled="landing.free"
      ></v-switch>
    </div>
    <div
      v-if="item.form.show"
      style="border:1px solid rgb(238,238,238);border-radius:5px;padding:15px;"
    >
      <div style="display:flex;margin-bottom:15px;">
        <v-spacer></v-spacer>
        <v-menu offset-y open-on-hover bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title style="cursor: pointer" @click="openSettings"
              >{{settings?'Content':'General'}}  Settings</v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title style="cursor: pointer" @click="openStyles"
              >{{styles?'Content':'Style'}} Settings</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div v-if="!styles&&!settings">
        <div
          class="control"
          v-for="(form, indexForm) in item.form.pro"
          :key="form.id"
          style="margin-bottom: 20px;border: 1px solid #eee;padding: 15px;border-radius: 5px;"
        >
          <div style="display:flex;align-items:center;margin-bottom:15px;">
            <label class="label" style="display:block;margin-bottom:0;margin-right:15px;">Group title</label>
            <v-text-field
                outlined
                solo
                hide-details
                flat
                style="margin-right:15px;"
                dense
                v-model="form.title"
            ></v-text-field>
            <v-menu offset-y open-on-hover bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title style="cursor: pointer" @click="openCreateDialog(indexForm)"
                  >Add item</v-list-item-title
                  >
                </v-list-item>
                <v-list-item>
                  <v-list-item-title style="cursor: pointer" @click="deleteFormGroupOpen(indexForm)"
                  >Delete</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <v-data-table
            :headers="headers"
            :items="form.content"
            class="elevation-1"
            hide-default-footer
          >
            <template v-slot:body>
              <draggable v-model="form.content" tag="tbody">
                <tr v-for="(item, index) in form.content" :key="index">
                  <td class="d-block d-sm-table-cell" style="text-align:center;">{{ item.radio }}</td>
                  <td class="d-block d-sm-table-cell" style="text-align:center;">{{ item.label }}</td>
                  <td class="d-block d-sm-table-cell" style="text-align:center;">{{ item.price }}</td>
                  <td class="d-block d-sm-table-cell" style="text-align:center;">
                    <v-menu offset-y open-on-hover bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">
                          <v-icon>mdi-cog</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title style="cursor: pointer" @click="openEditDialog(indexForm, index)"
                          >Edit</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title style="cursor: pointer" @click="deleteFormOne(indexForm,index)"
                          >Delete</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </draggable>
            </template>
          </v-data-table>
        </div>
        <v-btn
          style="margin-left: auto; display: flex"
          class="mb-4"
          color="primary"
          outlined
          small
          @click="addFormGroup(item.form.pro)"
        >
          + Add Form Group
        </v-btn>
      </div>
      <div v-if="styles">
        <FormOrderStyles :item="item" @openStyles="openStyles"/>
      </div>
      <div v-if="settings">
        <FormOrderSettings :item="item" @openSettings="openSettings"/>
      </div>
    </div>
    <v-dialog v-model="dialogCreate" max-width="900px">
      <v-card>
        <v-icon
          style="top: 10px;right: 10px;position: absolute;"
          class="close__modal"
          @click="closeCreateDialog"
          >mdi-close</v-icon
        >
        <v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="text-h5">New Form Item</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label class="label">Radio type</label>

                <v-tabs v-model="addItemForm.radio">
                  <v-tab
                      v-for="type in radioTypes" :key="type.id" :href="'#'+type.value">
                    {{type.label}}
                  </v-tab>
                  <v-tab-item :value="type.value"
                              v-for="type in radioTypes"
                              :key="type.id+'item'">
                    <v-row style="padding-top:15px;">
                      <v-col cols="12" sm="12" md="12">
                        <label class="label">Label</label>
                        <v-text-field
                            v-model="addItemForm.label"
                            outlined
                            dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                          cols="6"
                          v-if="
                  addItemForm.radio === 'radio' ||
                    addItemForm.radio === 'checkbox' ||
                    addItemForm.radio === 'number' ||
                    addItemForm.radio === 'text'
                "
                      >
                        <label class="label">Price</label>
                        <v-currency-field
                            v-model="addItemForm.price"
                            outlined
                            dense
                        ></v-currency-field>
                      </v-col>
                      <v-col
                          :cols="
                  addItemForm.radio === 'radio' ||
                  addItemForm.radio === 'checkbox' ||
                  addItemForm.radio === 'number' ||
                  addItemForm.radio === 'text'
                    ? '6'
                    : '12'
                "
                      >
                        <label class="label">External id</label>
                        <v-text-field
                            v-model="addItemForm.id"
                            outlined
                            dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="primary"
            style="margin-bottom: 15px"
            small
            @click="saveFormPro()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="700px">
      <v-card>
        <v-icon
          style="top: 10px;right: 10px;position: absolute;"
          class="close__modal"
          @click="closeEditDialog"
          >mdi-close</v-icon
        >
        <v-card-title style="margin-bottom: 20px">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="text-h5">Edit Form Item</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label class="label">Radio type</label>
                <v-tabs v-model="editItem.radio">
                  <v-tab
                      v-for="type in radioTypes" :key="type.id" :href="'#'+type.value">
                    {{type.label}}
                  </v-tab>
                  <v-tab-item :value="type.value"
                      v-for="type in radioTypes"
                      :key="type.id+'item'">
                    <v-row style="padding-top:15px;">
                    <v-col cols="12" sm="12" md="12">
                      <label class="label">Label</label>
                      <v-text-field
                          v-model="editItem.label"
                          outlined
                          dense
                      ></v-text-field>
                    </v-col>
                    </v-row>
                    <v-row>
                    <v-col
                        cols="6"
                        v-if="
                  editItem.radio === 'radio' ||
                    editItem.radio === 'checkbox' ||
                    editItem.radio === 'number' ||
                    editItem.radio === 'text'
                "
                    >
                      <label class="label">Price</label>
                      <v-currency-field
                          v-model="editItem.price"
                          outlined
                          dense
                      ></v-currency-field>
                    </v-col>
                    <v-col
                        :cols="
                  editItem.radio === 'radio' ||
                  editItem.radio === 'checkbox' ||
                  editItem.radio === 'number' ||
                  editItem.radio === 'text'
                    ? '6'
                    : '12'
                "
                    >
                      <label class="label">External id</label>
                      <v-text-field
                          v-model="editItem.id"
                          outlined
                          dense
                      ></v-text-field>
                    </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="primary"
            style="margin-bottom: 15px"
            small
            @click="saveEdit"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PopupTariff ref="PopupTariff" />
    <PopupSuccess ref="PopupSuccess" :title="'Thank You!'" :content="successMessage" />
    <PopupDelete
      ref="PopupDeleteFormPro"
      @confirm="deleteFormPro(item.form.pro)"
      :title="'Are you sure about delete?'"
    />
    <PopupDelete
        ref="PopupDeleteSubFormPro"
        @confirm="deleteSubFormPro()"
        :title="'Are you sure about delete?'"
    />
  </div>
</template>

<script>
import PopupSuccess from "@/components/Popup/PopupSuccess.vue";
import PopupDelete from "@/components/Popup/PopupDelete.vue";
import PopupTariff from "@/components/Popup/PopupTariff.vue";
import draggable from "vuedraggable";
import { mapState } from "vuex";
import moment from "moment";
import FormOrderStyles from "./FormOrder/FormOrderStyles.vue"
import FormOrderSettings from "./FormOrder/FormOrderSettings.vue"
export default {
  name: "FormOrder",
  props: ["item"],
  components: {
    FormOrderSettings,
    PopupSuccess,
    PopupDelete,
    draggable,
    FormOrderStyles,
    PopupTariff,
  },
  data() {
    return {
      deleteFormOneIndex: {
        group:null,
        item:null
      },
      headers:[
        {
          text: 'Type',
          value: 'radio',
          align:"center",
          sortable: false
        },
        {
          text: 'Label',
          value: 'label',
          align:"center",
          sortable: false
        },
        {
          text: 'Price',
          value: 'price',
          align:"center",
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          align:"center",
          sortable: false
        },
      ],
      dialogCreate: false,
      editDialog: false,
      styles: false,
      show_btn_name_color:false,
      show_btn_bg_color:false,
      show_btn_border_color:false,
      show_btn_label_color: false,
      show_btn_title_color: false,
      show_btn_price_color: false,
      createGroupIndex: 0,
      settings:false,
      successMessage: "",
      orderTypes:[
        { id: 0, value: "request", label: "Request" },
        { id: 1, value: "items", label: "Items" },
      ],
      radioTypes: [
        { id: 0, value: "checkbox", label: "Checkbox" },
        { id: 1, value: "number", label: "Number" },
        { id: 2, value: "text", label: "Text" },
        { id: 3, value: "date", label: "Date" },
        { id: 4, value: "time", label: "Time" },
        { id: 5, value: "notice", label: "Notice" },
        { id: 6, value: "radio", label: "Radio" },
      ],
      dropzoneOptions: {
        addRemoveLinks: true,
        maxFile:1,
        maxFileSize:3,
        destroyDropzone: false,
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        dictFileTooBig: "File is too big ({{filesize}}mb). Max file size: 3mb",
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
      },
      defaultFormPro: {
        id: null,
        formDialog: false,
        title: "default title",
        addItem: false,
        groupCheck: false,
        content: [
          {
            id: 0,
            radio: "checkbox",
            label: "default label",
            label_color: "#000000",
            price: "0",
            count: 1,
            formDialogEdit: false,
            price_color: "#000000",
          },
        ],
      },
      addItemForm: {
        id: 0,
        radio: "checkbox",
        label: "default label",
        label_color: "#000000",
        text:"",
        price: "0",
        formDialogEdit: false,
        count: 1,
        price_color: "#000000",
      },
      editItem: {
        id: 0,
        radio: "checkbox",
        label: "default label",
        label_color: "#000000",
        price: "0",
        text:"",
        count: 1,
        formDialogEdit: false,
        price_color: "#000000",
      },
      delete_form_group_index: null,
    };
  },
  computed: {
    ...mapState(["landing"]),
  },
  methods: {
    deleteFormOne(groupIndex,itemIndex){
      this.deleteFormOneIndex.group = groupIndex;
      this.deleteFormOneIndex.item = itemIndex;
      this.$refs.PopupDeleteSubFormPro.open();
    },
    openSettings(){
      this.settings = !this.settings;
      this.styles = false
    },
    openStyles(){
      this.styles = !this.styles;
      this.settings = false;
    },
    mountDropzone(){
      if(this.item.form.title_img){
        let mockFile = {
          accepted: true,
          kind: "image",
          ...this.item.form.title_img,
        };
        this.$refs.upload.manuallyAddFile(mockFile,  this.item.form.title_img);
        this.$refs.upload.dropzone.emit("thumbnail", mockFile, this.item.form.title_img);
        this.$refs.upload.dropzone.emit("complete", mockFile);
      }
    },
    async customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append("lending_settings_id", this.landing.settings_id);
      formData.append("image", _file);
    },
    logoMaxFileSexceeded(file) {
      this.$refs.upload.removeAllFiles();
      this.$refs.upload.addFile(file);
    },
    async handleRemove() {
      this.item.form.title_img = null;
    },
    handleSuccess(file) {
      this.item.form.title_img = file.xhr.response;
    },
    showStyles() {
      this.styles = !this.styles;
    },
    openCreateDialog(formGroupIndex) {
      this.createGroupIndex = formGroupIndex;
      this.dialogCreate = true;
    },
    closeCreateDialog() {
      this.createGroupIndex = 0;
      this.dialogCreate = false;
    },
    openEditDialog(formGroupIndex, formItemIndex) {
      this.editItem = JSON.parse(
        JSON.stringify(
          this.item.form.pro[formGroupIndex].content[formItemIndex]
        )
      );
      this.editGroupIndex = formGroupIndex;
      this.editItemIndex = formItemIndex;
      this.editDialog = true;
    },
    saveEdit() {
      this.editDialog = false;
      if (this.editItem.radio === "date") {
        this.editItem.text = moment().format("YYYY-MM-DD");
      }
      if (this.editItem.radio === "time") {
        this.editItem.text = moment().format("HH:mm");
      }
      this.item.form.pro[this.editGroupIndex].content.splice(
        this.editItemIndex,
        1,
        JSON.parse(JSON.stringify(this.editItem))
      );
      this.editGroupIndex = 0;
      this.editItemIndex = 0;
      this.editItem = {
        id: 0,
        radio: "checkbox",
        label: "default label",
        label_color: "#000000",
        price: "0",
        text:"",
        count: 1,
        formDialogEdit: false,
        price_color: "#000000",
      };
      this.successMessage = "Form edited successful";
      this.$refs.PopupSuccess.open();
    },
    closeEditDialog() {
      this.editDialog = false;
      this.editGroupIndex = 0;
      this.editItemIndex = 0;
      this.editItem = {
        id: 0,
        radio: "checkbox",
        label: "default label",
        label_color: "#000000",
        price: "0",
        text:"",
        count: 1,
        formDialogEdit: false,
        price_color: "#000000",
      };
    },
    billing() {
      let obj = this.$store.state.objects.list.find(
        (x) => +x.id === +this.$route.params.id
      );
      this.$refs.PopupTariff.open(obj);
    },
    deleteFormPro(arr) {
      arr.splice(this.delete_form_group_index, 1);
      this.delete_form_group_index = null;
    },
    deleteFormGroupOpen(index) {
      this.delete_form_group_index = index;
      this.$refs.PopupDeleteFormPro.open();
    },
    addFormGroup(arr) {
      const newArr = JSON.parse(JSON.stringify(this.defaultFormPro));
      newArr.id = arr.length ? arr.length : 0;
      arr.push(newArr);
      this.successMessage = "New form group added successful";
      this.$refs.PopupSuccess.open();
    },
    deleteSubFormPro() {
      this.item.form.pro[this.deleteFormOneIndex.group].content.splice(this.deleteFormOneIndex.item,1);
      this.successMessage = "Form item deleted successful";
      this.$refs.PopupSuccess.open();
    },
    saveFormPro() {
      let arr = this.item.form.pro[this.createGroupIndex];
      if (this.addItemForm.radio === "date") {
        this.addItemForm.text = moment().format("YYYY-MM-DD");
      }
      if (this.addItemForm.radio === "time") {
        this.addItemForm.text = moment().format("HH:mm");
      }
      let obj = JSON.parse(JSON.stringify(this.addItemForm));
      arr.content.push(obj);
      this.dialogCreate = false;
      this.successMessage = "New form added successful";
      this.$refs.PopupSuccess.open();
      setTimeout(() => {
        this.addItemForm.id = null;
        this.addItemForm.radio = "checkbox";
        this.addItemForm.label = "default label";
        this.addItemForm.label_color = "#000000";
        this.addItemForm.price = "0";
        this.addItemForm.text = "";
        this.addItemForm.price_color = "#000000";
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped></style>
